//@import '@angular/cdk/overlay-prebuilt.css';
//@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import '../node_modules/@angular/material/theming';
@import '@angular/material/theming';
@include mat-core();

$dynamic-theme-primary: (
    50: var(--theme-primary-50),
    100: var(--theme-primary-100),
    200: var(--theme-primary-200),
    300: var(--theme-primary-300),
    400: var(--theme-primary-400),
    500: var(--theme-primary-500),
    600: var(--theme-primary-600),
    700: var(--theme-primary-700),
    800: var(--theme-primary-800),
    900: var(--theme-primary-900),
    A100: var(--theme-primary-A100),
    A200: var(--theme-primary-A200),
    A400: var(--theme-primary-A400),
    A700: var(--theme-primary-A700),
    contrast: (
        50: var(--theme-primary-contrast-50),
        100: var(--theme-primary-contrast-100),
        200: var(--theme-primary-contrast-200),
        300: var(--theme-primary-contrast-300),
        400: var(--theme-primary-contrast-400),
        500: var(--theme-primary-contrast-500),
        600: var(--theme-primary-contrast-600),
        700: var(--theme-primary-contrast-700),
        800: var(--theme-primary-contrast-800),
        900: var(--theme-primary-contrast-900),
        A100: var(--theme-primary-contrast-A100),
        A200: var(--theme-primary-contrast-A200),
        A400: var(--theme-primary-contrast-A400),
        A700: var(--theme-primary-contrast-A700),
    ),
);

$dynamic-theme-secondary: (
    50: var(--theme-secondary-50),
    100: var(--theme-secondary-100),
    200: var(--theme-secondary-200),
    300: var(--theme-secondary-300),
    400: var(--theme-secondary-400),
    500: var(--theme-secondary-500),
    600: var(--theme-secondary-600),
    700: var(--theme-secondary-700),
    800: var(--theme-secondary-800),
    900: var(--theme-secondary-900),
    A100: var(--theme-secondary-A100),
    A200: var(--theme-secondary-A200),
    A400: var(--theme-secondary-A400),
    A700: var(--theme-secondary-A700),
    contrast: (
        50: var(--theme-secondary-contrast-50),
        100: var(--theme-secondary-contrast-100),
        200: var(--theme-secondary-contrast-200),
        300: var(--theme-secondary-contrast-300),
        400: var(--theme-secondary-contrast-400),
        500: var(--theme-secondary-contrast-500),
        600: var(--theme-secondary-contrast-600),
        700: var(--theme-secondary-contrast-700),
        800: var(--theme-secondary-contrast-800),
        900: var(--theme-secondary-contrast-900),
        A100: var(--theme-secondary-contrast-A100),
        A200: var(--theme-secondary-contrast-A200),
        A400: var(--theme-secondary-contrast-A400),
        A700: var(--theme-secondary-contrast-A700),
    ),
);

$app-theme: mat-light-theme(mat-palette($dynamic-theme-primary), mat-palette($dynamic-theme-secondary), mat-palette($mat-red));

@include angular-material-theme($app-theme);

/* You can add global styles to this file, and also import other style files */
body {
    font-family: 'Lato', Arial, sans-serif;
    line-height: 14pt;
    font-size: 14px;
    margin: 0;
    font-weight: normal;
    position: relative;
}

.cdk-overlay-pane {
    margin-top: 20px !important;
}

.mat-select-value {
    padding-left: 15px;
}
.mat-input-invalid .mat-input-placeholder {
    color: red;
}

.mat-input-invalid .mat-input-ripple {
    background-color: red;
}

.mat-error {
    font-size: 12px;
    margin-top: -18px;
    margin-bottom: 5px;
}

@keyframes pulse {
    0% {
        background-color: rgba(165, 165, 165, 0.1);
    }
    50% {
        background-color: rgba(165, 165, 165, 0.3);
    }
    100% {
        background-color: rgba(165, 165, 165, 0.1);
    }
}
